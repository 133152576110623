<template>
  <v-container class="ak-page-container">
    <PageTitle :title="learningUnit.name" />
    <v-row no-gutters justify="center" class="flex-column mb-10">
      <v-col v-for="(item, index) in learningUnit.levels" :key="index">
        <p class="ak-level-title">{{ item.name }}</p>
        <LearnUnitLevelList
          :levelId="item.id"
          :unitTitle="learningUnit.name"
          :unit-id="learningUnit.id"
          :challenge="item.challenge"
          @updateList="updateList"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from '@/components/common/PageTitle';
import LearnUnitLevelList from '@/components/modules/LearnUnit/LearnUnitLevelList';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  name: 'LearnUnitDetails',
  components: { PageTitle, LearnUnitLevelList },
  mixins: [CommonMixins],

  data() {
    return {
      learningUnit: {
        id: this.$route.params.unitId,
        name: null,
        levels: [],
      },
    };
  },

  created() {
    this.getUnitInfo();
    this.getLevelsForUnit();
  },

  methods: {
    updateList() {
      this.$store.dispatch('user/loadUserData');
      setTimeout(() => {
        this.getUnitInfo();
        this.getLevelsForUnit();
      }, 1000);
    },

    getUnitInfo() {
      this.$axios
        .get(
          `/api/v1/user/learningunits/${this.$route.params.unitId}`,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.data.data) {
            this.learningUnit.name = res.data.data.name;
          }
        });

      this.$forceUpdate();
    },

    getLevelsForUnit() {
      this.learningUnit.levels = [];
      this.$axios
        .get(
          `/api/v1/user/learningunits/${this.$route.params.unitId}/levels`,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              let postLink = null;
              let getLink = null;
              let getLinkSummary = null;
              if (item.links) {
                postLink = item.links.filter(
                  (el) => el.method == 'POST' && el.rel == 'challenge'
                );

                getLink = item.links.filter(
                  (el) => el.method == 'GET' && el.rel == 'challenge'
                );

                getLinkSummary = item.links.filter(
                  (el) => el.method == 'GET' && el.rel == 'summary'
                );
              }

              this.learningUnit.levels.push({
                id: item.data.id,
                name: item.data.name,
                locked: item.data.locked,
                challenge: {
                  data: item.data.challenge,
                  challengeLinkGet:
                    getLink !== null
                      ? getLink.length > 0
                        ? getLink[0].href
                        : null
                      : null,
                  challengeLinkPostRun:
                    postLink !== null
                      ? postLink.length > 0
                        ? postLink[0].href
                        : null
                      : null,
                  challengeLinkGetSummary:
                    getLinkSummary !== null
                      ? getLinkSummary.length > 0
                        ? getLinkSummary[0].href
                        : null
                      : null,
                },
              });
            });
          }
        });

      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-level-title {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 13px;
}
</style>
