<template>
  <v-col cols="12" class="pa-0 pb-10 mb-2">
    <LearnUnitLevelItem
      v-for="item in games"
      :key="item.id"
      :levelTitle="item.name"
      :gameStatus="item.completed"
      :isLocked="item.locked"
      :gameLinkPostRun="item.gameLinkPostRun"
      :gameLinkGetRun="item.gameLinkGetRun"
      :unitTitle="unitTitle"
      :unit-id="unitId"
      :levelId="levelId"
      :gameId="item.id"
      @updateList="updateList"
    />

    <LearnUnitLevelItem
      :levelTitle="challenge.data.name"
      :gameStatus="challenge.data.completed"
      :unitTitle="unitTitle"
      :unit-id="unitId"
      :levelId="levelId"
      :isLocked="challenge.data.locked"
      :gameId="challenge.data.id"
      @updateList="updateList"
      :challengeLinkGet="challenge.challengeLinkGet"
      :challengeLinkPostRun="challenge.challengeLinkPostRun"
      :challengeLinkGetSummary="challenge.challengeLinkGetSummary"
      :isGame="false"
    />
  </v-col>
</template>

<script>
import LearnUnitLevelItem from '@/components/modules/LearnUnit/LearnUnitLevelItem';

export default {
  name: 'LearnUnitLevelList',
  components: { LearnUnitLevelItem },
  props: {
    levelId: {
      type: String,
      default: null,
    },
    challenge: {
      type: Object,
      default: () => {},
    },
    unitTitle: {
      type: String,
      default: 'Unit title',
    },
    unitId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      games: [],
      unlockedChallenge: false,
    };
  },

  created() {
    this.getGamesByLevel();
  },

  methods: {
    updateList() {
      this.$emit('updateList');
    },

    getGamesByLevel() {
      this.games = [];
      this.$axios
        .get(
          `/api/v1/user/learningunits/${this.$route.params.unitId}/levels/${this.levelId}/games/`,
          this.axiosGetConfig
        )
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((el) => {
              let postLink = null;
              let getLink = null;
              if (el.links) {
                postLink = el.links.filter(
                  (item) => item.method == 'POST' && item.rel == 'run'
                );

                getLink = el.links.filter(
                  (item) => item.method == 'GET' && item.rel == 'run'
                );
              }

              this.games.push({
                id: el.data.id,
                name: el.data.name,
                completed: el.data.completed,
                gameLinkPostRun:
                  postLink !== null
                    ? postLink.length > 0
                      ? postLink[0].href
                      : null
                    : null,
                gameLinkGetRun:
                  getLink !== null
                    ? getLink.length > 0
                      ? getLink[0].href
                      : null
                    : null,
                locked: el.data.locked,
              });
            });
          }
        });

      this.$forceUpdate();
    },
  },
};
</script>
